import React from 'react';
import Navbar from '../../Components/Navbar';
import { Box, Container, Divider, Text, OrderedList, VStack } from '@chakra-ui/react';
import Footer from '../../Components/Footer';
import { UnorderedList, ListItem } from '@chakra-ui/react';

const TermsOfUse = () => {
  return (
    <>
      <VStack spacing={"auto"}>
        <Navbar />
        <Box
          backgroundImage={"url(/images/movies/exploreBanner.jpg)"}
          backgroundPosition={"center"}
          backgroundSize={"cover"}
          backgroundRepeat={"no-repeat"}
          h={"250px"}
          w={"full"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text fontSize={{ base: "40px", lg: "96px" }} fontWeight={"500"} color={"white"}>
            Terms of Use
          </Text>
        </Box>

        <Container maxW="container.xl" py={{ base: 8, md: 12 }}>
          <Box textAlign={"start"}>

            {/* Section 1 */}

            <Box>
            <Text fontSize={"lg"} fontWeight={"semibold"}>
              Effective Date: October 05, 2024
            </Text>

            <Text fontSize={"2xl"} fontWeight={"semibold"} mt={4}>
              Section 1. Welcome to GorillaFunder!
            </Text>

            <Text fontSize={"lg"} mt={4}>
              This page contains our Terms of Use. When you use GorillaFunder, you’re agreeing to all the rules below.
            </Text>

            <Text fontSize={"lg"} mt={4}>
              By using this website (the “Site”) and any of the services (together with the Site, the “Services”) offered by GorillaFunder, PBC (together with The Creative Independent, LLC, Project Partners, LLC, and their parents, subsidiaries, affiliates, agents, representatives, consultants, employees, officers, and directors—collectively, “GorillaFunder,” “we,” or “us”), you’re agreeing to these legally binding rules (the “Terms” or “Terms of Use”). You’re also agreeing to our Privacy Policy and Cookie Policy, and agreeing to follow any other rules on the Site, including our Community Guidelines and rules for starting projects, which are incorporated into these Terms by reference.
            </Text>

            <Text fontSize={"lg"} mt={4}>
              We may update these Terms from time to time as laws, our Services, and practices change. We encourage you to review these Terms periodically. If you keep using GorillaFunder after a change, we will assume you accept the current version. These Terms may be translated into another language for your convenience; in the event of a conflict between the English version and any translations, the English version shall control.
            </Text>

            <Text fontSize={"lg"} mt={4}>
              GorillaFunder is for your personal, non-commercial use, except as explained in section 4 and section 5 below.
            </Text>
            </Box>

            <Divider my={10} />

              {/* Section 2 */}
<Box>
            {/* Section 2 */}
            <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
              Section 2. About Creating an Account
            </Text>

            <Text fontSize={"lg"} mt={4}>
              You must be 18 years old or over to sign up for a GorillaFunder account. You’re responsible for your account and all the activity on it.
            </Text>

            <Text fontSize={"lg"} mt={4}>
              You can browse GorillaFunder without registering for an account. But to use some of GorillaFunder’s functions, you’ll need to register, which includes choosing a username and setting a password. When you do that, all of the information you give us has to be accurate and complete. Don’t impersonate anyone else or choose names that are offensive or that violate anyone’s rights. If you don’t follow these rules, we may cancel your account.
            </Text>

            <Text fontSize={"lg"} mt={4}>
              You’re responsible for all the activity on your account and for keeping your password confidential. If you find out that someone has used your account without your permission, you should report it to GorillaFunder Support.
            </Text>
</Box>
     
     <Divider my={10} />

  {/* Section 3 */}
<Box>
            {/* Section 2 */}
            <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
            Section 3. Things You Definitely Shouldn’t Do
            </Text>

            <Text fontSize={"lg"} mt={4}>
            This section is a list of things you probably already know you shouldn’t do—lie, break laws, abuse people, steal data, hack other people’s computers, and so on. Please behave yourself. Don’t do this stuff.
            </Text>

            <Text fontSize={"lg"} mt={4}>
            Millions of people use GorillaFunder. We expect all of them to behave responsibly and help keep this a nice place. Whether you are a visitor, creator, collaborator or backer, don’t do any of these things on the Site. We reserve the right to take any action we think appropriate if you do so, including suspending or terminating your account
            </Text>


            <UnorderedList typeof='' mt={"20px"}>
  <ListItem mt={4}> <b>Don’t break the law.</b>  Don’t take any action that infringes or violates other people’s rights, violates the law, or breaches any contract or legal duty you have toward anyone.</ListItem>
  <ListItem mt={4}><b>Don’t lie to people.</b> Don’t post information you know is false, misleading, or inaccurate. Don’t do anything deceptive or fraudulent.</ListItem>
  <ListItem mt={4}><b>Don’t offer prohibited items.</b> Don’t offer any rewards that are illegal, violate any of GorillaFunder’s policies, rules, or guidelines, or violate any applicable law, statute, ordinance, or regulation.</ListItem>
  <ListItem mt={4}><b>Don’t victimize anyone.</b>  Don’t do anything threatening, abusive, harassing, defamatory, libelous, tortious, obscene, profane, or invasive of another person’s privacy.</ListItem>
  <ListItem mt={4}><b>Don’t spam.</b> Don’t distribute unsolicited or unauthorized advertising, marketing or promotional material, or any junk mail, spam, or chain letters. Don’t run mail lists, listservs, or any kind of auto-responder or spam on or through the Site, including in comments you post to projects you back.</ListItem>
  <ListItem mt={4}><b>Don’t harm anyone’s computer.</b> Don’t distribute software viruses, or anything else (code, films, programs) designed to interfere with the proper function of any software, hardware, or equipment on the Site (whether it belongs to GorillaFunder or another party).</ListItem>
  <ListItem mt={4}><b>Don’t abuse other users’ personal information.</b> When you use GorillaFunder — and especially if your project is successfully funded — you may receive information about other users including backers of your project, such as their names, email addresses, and postal addresses, either from GorillaFunder or from them directly. This information is provided for the sole purpose of participating in a GorillaFunder project: don’t use it for other purposes, and don’t abuse it. If you share this information with authorized collaborators, you are responsible for ensuring they understand and adhere to the restrictions and conditions imposed in these Terms including the Privacy Policy.</ListItem>
</UnorderedList>

            <Text fontSize={"lg"} mt={4}>
            We also need to make sure that the Site is secure and our systems function properly. So don’t do any of these things—most of which boil down to “don’t mess with our system.”
            </Text>



            <UnorderedList typeof='' mt={"20px"}>
  <ListItem mt={4}><b>Don’t try to interfere with the proper workings of the Services.</b></ListItem>
  <ListItem mt={4}><b>Don’t bypass any measures we’ve put in place to secure the Services.</b></ListItem>
  <ListItem mt={4}><b>Don’t try to damage or get unauthorized access to any system, data, password, or other information, whether it belongs to GorillaFunder or another party.</b></ListItem>
  <ListItem mt={4}><b>Don’t take any action that imposes an unreasonable load on our infrastructure, or on our third-party providers.</b> (We reserve the right to determine what’s reasonable.)</ListItem>
  <ListItem mt={4}><b>Don’t use any kind of software or device (whether it’s manual or automated) to “crawl” or “spider” any part of the Site.</b></ListItem>
  <ListItem mt={4}><b>Don’t take apart or reverse engineer any aspect of GorillaFunder</b> in an effort to access things like source code, underlying ideas, or algorithms.</ListItem>
</UnorderedList>



</Box>
     





     <Divider my={10} />

     
  {/* Section 4 */}

<Box>
            {/* Section 4 */}
            <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
           Section 4. How Projects Work
            </Text>

            <Text fontSize={"lg"} mt={4}>
            Most of our Terms of Use explain your relationship with GorillaFunder. This section is different—it explains the relationship between creators and backers of GorillaFunder projects, and who’s responsible for what. This is what you’re agreeing to when you create or back a GorillaFunder project.
            </Text>

            <Text fontSize={"lg"} mt={4}>
            GorillaFunder provides a funding platform for creative projects that have yet to come to life. When a creator posts a project on GorillaFunder, they’re inviting other people to form a contract with them. Anyone who backs a project is accepting the creator’s offer, and forming that contract.
            </Text>
            <Text fontSize={"lg"} mt={4}>
            GorillaFunder is not a part of this contract—the contract is a direct agreement between creators and their backers. To the extent these Terms conflict with any supplemental or additional terms that are applied by a creator to their project, these Terms prevail. Here are the terms that govern the agreement between a creator and their backers:
            </Text>

            <Text fontSize={"lg"} mt={4}>
            1. During a campaign, and especially when a project is successfully funded, creators owe their backers a high standard of effort, honest communication, and a dedication to bringing the project to life. At the same time, backers agree and acknowledge they’re not buying something when they back a project—they’re helping to create something new, not ordering something that already exists. Every project is different. There may be changes or delays, and there’s a chance something could happen that prevents the creator from being able to finish the project, which is not guaranteed.
            </Text>
            <Text fontSize={"lg"} mt={4}>
            2. If a creator is unable to complete their project and fulfill rewards, they must make a  reasonable effort to find another way of bringing the project to the best possible conclusion for backers. A creator in this position has remedied the situation and met their obligations to backers if:
            </Text>


            <UnorderedList typeof='' mt={"20px"}>
  <ListItem mt={4}> they post an update that explains what work has been done, how funds were used, and what prevents them from finishing the project as planned;</ListItem>
  <ListItem mt={4}>they work diligently and in good faith to bring the project to the best possible conclusion under the circumstances, in a timeframe that’s communicated to backers;</ListItem>
  <ListItem mt={4}><b>Don’t offer prohibited items.</b> Don’t offer any rewards that are illegal, violate any of GorillaFunder’s policies, rules, or guidelines, or violate any applicable law, statute, ordinance, or regulation.</ListItem>

  <ListItem mt={4}>they’re able to demonstrate that they’ve used funds appropriately and made every reasonable effort to complete the project as promised;</ListItem>

  <ListItem mt={4}>they’ve been honest, and have made no material misrepresentations in their communication to backers; and</ListItem>

  <ListItem mt={4}>they offer to return any remaining funds to backers who have not received their reward (in proportion to the amounts pledged), or else explain how those funds will be used to complete the project in some alternate form.</ListItem>
</UnorderedList>

            <Text fontSize={"lg"} mt={4}>
            3. The creator is solely responsible for fulfilling their project. If they’re unable to satisfy these terms, they may be subject to legal action by backers. GorillaFunder reserves the right to take any action it deems appropriate with respect to campaign funds while a dispute is pending.
            </Text>



     



</Box>



<Divider my={10} />

{/* Section 5 */}
<Box>
  <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Section 5. How Funding Works
  </Text>

  <Text fontSize={"lg"} mt={4}>
    This section goes over the details of backing and creating projects—things like how money gets collected, whether pledges can be changed or canceled, and how creators can contact backers to provide rewards.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    These are the terms that apply when you back a project during a live campaign before the funding deadline:
  </Text>

  <Text fontSize={"lg"} mt={4}>
    You’re only charged if the project reaches its fundraising goal. You’ll provide your payment information when you pledge, but you won’t be charged. Your payment will only be collected if, at the time of the project’s funding deadline, the project has reached its fundraising goal. The amount you pledged is the amount GorillaFunder will collect. If the campaign doesn’t reach its fundraising goal, you won’t be charged, no funds will be collected, and no money will change hands.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    In some cases, we’ll reserve the charge on your card. GorillaFunder and its payment processors may authorize or reserve a charge on your credit card (or whatever payment method you use) for any amount up to the full amount of the pledge, at any time between the date you make a pledge and the collection of funds.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    You can change or cancel your pledge at any time before the project’s funding deadline (with one exception). You can increase, decrease, or cancel your pledge at any time during the campaign, with one exception. During the last 24 hours of a campaign, you can’t decrease or cancel your pledge without contacting GorillaFunder customer support first if doing so would drop the project below its funding goal. Once the project has been funded, you can only cancel or change your pledge by making special arrangements directly with the creator. GorillaFunder is not responsible for cancellations or refunds.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    These are the terms that apply when you back a project by making a late pledge after the live campaign has successfully ended:
  </Text>

  <Text fontSize={"lg"} mt={4}>
    You are charged at the time of pledge. When you make a late pledge, your card will be charged immediately.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    You cannot cancel or change your pledge. You may, however, request a refund from the creator. GorillaFunder is not responsible for cancellations or refunds.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    These are the terms that apply to all pledges, whether made during the live campaign or as a late pledge:
  </Text>

  <Text fontSize={"lg"} mt={4}>
    The estimated fulfillment date is the creator’s estimate. If the creator has offered rewards, any month and year given for each reward is only the creator’s estimate and not a guarantee they will fulfill by that time. The schedule may change as the creator works on the project. We ask creators to plan carefully, set estimated delivery dates they feel confident they can work toward, and communicate with backers about any changes.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    The creator may need to send you questions about any reward you have selected. The creator might need information from you, like your mailing address or t-shirt size. They’ll request that information after the campaign has successfully funded or, if you have made a late pledge, after you have made your late pledge. To receive any reward, you’ll need to provide the information in a reasonable amount of time. Creators should not ask for personal information that is not necessary to provide your reward, and should never request sensitive personal information such as your Social Security number or payment information. Contact us at GorillaFunder Support if you receive a request for information that seems inappropriate or excessive.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    GorillaFunder doesn’t offer refunds. Responsibility for finishing a project lies entirely with the project creator. GorillaFunder doesn’t hold funds on creators’ behalf, cannot and does not guarantee creators’ work, and does not offer refunds.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    These are the terms that apply when you’re creating and running a project:
  </Text>

  <Text fontSize={"lg"} mt={4}>
    You can refund individual pledges if you want. After your project has been funded, you can cancel and refund a backer’s collected pledge at any time. If you fully refund a backer’s pledge, you have no further obligation to that specific backer, and the agreement between you and the backer is terminated.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    We’ll charge our fees before sending funds to your bank account. GorillaFunder and its payment processors will deduct applicable fees before transmitting the proceeds of a campaign.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    Some pledges can’t be collected, which might reduce the amount of funding you get. Because some payments can’t be collected—for instance, when a backer’s credit card expires before a campaign ends and updated information isn’t provided—the amount of funding you receive may not be equal to the full amount pledged (less fees).
  </Text>

  <Text fontSize={"lg"} mt={4}>
    We'll try to help resolve payment disputes. If a backer of your project disputes the charge with their issuer, we will re-present the charge to settle the dispute with the issuer. You’ll be notified that a dispute has been filed, and you may be asked to provide evidence to help us resolve it in your favor. If the dispute is found valid, you are responsible for the amount of the chargeback, and authorize us to charge the credit card you provided to us for such purposes when you started your project for the amount of the dispute.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    Prepare for potential delays. Don’t assume you’ll be able to launch your project when you want; there could be a reason we’re not able to approve it immediately upon submission, or a problem may arise that takes time to resolve. Don’t assume you’ll be able to immediately collect your funding; there may be a delay between the end of a successful campaign and your access to the funds. Don’t take any actions in reliance on collecting any of the money pledged by a certain time. We encourage you to wait until you actually have the ability to withdraw it from your account and spend it.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    Late pledges. Our late pledges feature allows creators to continue to collect pledges from new backers after their crowdfunding campaign has ended, but only up until the time when they are first ready to fulfill the rewards they have offered. Creators must enable the late pledge feature, and may do so prior to and during their live campaign. Late pledge functionality cannot be enabled after a live campaign has ended. If you have enrolled to use this feature and the feature is enabled, then the following additional terms apply:
  </Text>

  <Text fontSize={"lg"} mt={4}>
    You may not offer the same reward tiers that were available during the live campaign at a lower pledge amount than offered during the live campaign. Those reward tiers must be offered at the same or a higher pledge amount.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    You may only accept late pledges up until the time you are first ready to fulfill the rewards you have offered. You are responsible for disabling late pledge functionality, which you may do so by de-activating late pledge functionality in project build.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    While GorillaFunder may provide you with reminders, including through periodic emails, when you download your backer report or when you send a backer survey, you are responsible for ensuring late pledge functionality is disabled, or notifying GorillaFunder promptly to disable it for you when you are first ready to fulfill rewards.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    Once processed, late pledges will be paid out to creators periodically, the exact timing of which may vary depending on factors such as processing times and reaching any reasonable minimum threshold payout amount established by GorillaFunder.
  </Text>

  <Text fontSize={"lg"} mt={4}>
    Late pledges are subject to the same fees charged by GorillaFunder on live campaign pledges, and incur the same payment processing fees.
  </Text>
</Box>



<Divider my={10} />


<Box>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Section 6: Stuff We Don’t Do and Aren’t Responsible For
      </Text>
      <UnorderedList spacing={2} mt={2}>
        <ListItem>
          <Text fontWeight="bold">No Oversight on Projects’ Performance:</Text>
          <Text>
            We don’t oversee projects’ performance and we don’t mediate disputes between users.
          </Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">Limitations of Liability:</Text>
          <Text>
            GorillaFunder isn’t liable for any damages or losses related to your use of the Services. We don’t become involved in disputes between users or between users and any third party relating to the use of the Services.
          </Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">Risks Involved:</Text>
          <Text>
            All content you access through the Services is at your own risk. You’re solely responsible for any resulting damage or loss to any party.
          </Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">Release of Claims:</Text>
          <Text>
            When you use the Services, you release GorillaFunder from claims, damages, and demands of every kind—known or unknown, suspected or unsuspected, disclosed or undisclosed—arising out of or in any way related to such disputes and the Services.
          </Text>
        </ListItem>
      </UnorderedList>
      </Box>
      
<Divider my={10} />

<Box>
      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Section 7: Our Fees
      </Text>
      <UnorderedList spacing={2} mt={2}>
        <ListItem>
          <Text fontWeight="bold">Platform Fee:</Text>
          <Text>
            We currently charge a <strong>5% platform fee</strong> on amounts raised by successfully funded projects and on late pledges, in addition to standard fees charged by our payment processors.
          </Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">Account Creation:</Text>
          <Text>
            Creating an account on GorillaFunder is free. If you create a project that reaches its funding goal, GorillaFunder collects a 5% platform fee, and our payment processor collects a standard processing fee from each collected pledge. This applies to late pledges as well.
          </Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">Payment Processing:</Text>
          <Text>
            Funds pledged by backers are collected by payment processors. Each payment processor is its own independent company, and GorillaFunder isn’t responsible for their actions. Our current payment processor is Stripe.
          </Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">Tax Responsibilities:</Text>
          <Text>
            You are responsible for paying any additional fees and all local, state, federal, or other taxes and assessments associated with your use of GorillaFunder. For example, creators are solely responsible for collecting and/or remitting any applicable taxes for amounts received in connection with their fundraising campaigns and projects.
          </Text>
        </ListItem>
      </UnorderedList>
</Box>
<Divider my={10} />

<Box>
      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Section 8: Other Websites
      </Text>
      <UnorderedList spacing={2} mt={2}>
        <ListItem>
          <Text fontWeight="bold">Links to Third-Party Websites:</Text>
          <Text>
            GorillaFunder may contain links to other websites. When you access third-party websites, you do so at your own risk. We don’t control or endorse those sites.
          </Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">Payment Processing Terms:</Text>
          <Text>
            GorillaFunder partners with other companies (such as Stripe) for payment processing. When you back or create a project, you’re also agreeing to the payment processor’s terms of service.
          </Text>
        </ListItem>
      </UnorderedList>
</Box>

<Divider my={10} />
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Section 9: Your Intellectual Property
</Text>
<Text fontSize={"lg"} mt={4}>
    We don't own the content you post on GorillaFunder, but you do grant us permission to use or copy it however we may need or deem appropriate. We generally use it to promote projects and showcase our community. Ultimately, you're responsible for the content you post, and you're promising us that it's ok to use.
</Text>
<Text fontSize={"lg"} mt={4}>
    GorillaFunder doesn’t own content you submit to us including for posting on your project page (your “Content”). But we do need certain licenses from you in order to perform our Services. When you submit a project for review, launch a project, or otherwise submit Content to us via email or any other means, you agree to these terms:
</Text>
<Text fontSize={"lg"} mt={4}>
    We can use the Content you’ve submitted. You grant to us, and others acting on our behalf, a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, sublicensable, transferable right to use, reproduce, make derivative works, publicly display, publicly perform, distribute, disseminate, communicate, commercialize, and exploit the copyright, publicity, trademark, and database rights with respect to your Content. In general, we use this Content to promote projects and showcase our community on the website, including through application of a Project We Love and other similar badges. There are many factors that we take into consideration before featuring a project on the homepage or giving it a Project We Love badge. This includes, but is not limited to, a crisp project image with no logos, badges, or text on top, a clear and detailed description that includes a thorough plan for completing your project, captivating images or videos, an excited community, what we think different users will be interested in, and of course, a lot of creativity. In addition to giving some projects a Project We Love badge, we also carefully curate the homepage to highlight great examples of GorillaFunder projects. The projects at the very top of the homepage typically are best-of-the-best Projects We Love—based on factors such as creative innovation, honesty, and alignment with GorillaFunder’s mission and charter —but we also use algorithms to surface popular projects, projects your friends have backed and launched, and projects recommended just for you. We may also recommend projects that are gaining traction as a result of their creator’s own advertising, and/or on the basis of a user’s prior search terms, browsing history, project backings and other interactions, and cookies we have collected during their visits to our website. GorillaFunder reserves the right to apply and remove all such badges, and to start and cease all such promotions, in its sole discretion.
</Text>
<Text fontSize={"lg"} mt={4}>
    When we use the Content, we can make changes, like editing or translating it. You grant us the right to make derivative works of your Content. This may include edits, modifications, reformatting, excerpting, or translating any of your Content.
</Text>
<Text fontSize={"lg"} mt={4}>
    You won’t submit stuff you don’t hold the copyright for (unless you have permission to grant us these rights in turn). Your Content must not contain third-party copyrighted material, or material that is subject to other third-party proprietary rights, unless you have permission from the rightful owner of the material and can pass those permissions along to us, or you are otherwise legally entitled to post the material (and to grant GorillaFunder all the license rights outlined here).
</Text>
<Text fontSize={"lg"} mt={4}>
    Any royalties or licensing fees owed by you to third parties are solely your responsibility. You will pay all royalties and other amounts owed to any person or entity associated with your Content, or that may be due as a result of posting that Content on GorillaFunder.
</Text>
<Text fontSize={"lg"} mt={4}>
    You promise that if we use your Content, we’re not violating anyone’s rights or copyrights. If GorillaFunder or its users exploit or make use of your submission in the ways contemplated in these Terms you promise that this will not infringe or violate the rights of any third party, including (without limitation) any privacy rights, publicity rights, copyrights, contract rights, or any other intellectual property or proprietary rights.
</Text>
<Text fontSize={"lg"} mt={4}>
    You’re responsible for the stuff you post. All information submitted to the Site, whether publicly posted or privately transmitted, is solely your responsibility.
</Text>
<Text fontSize={"lg"} mt={4}>
    We’re not responsible for mistakes in your Content. GorillaFunder is not liable for any errors or omissions in any Content.
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Section 10: GorillaFunder’s Intellectual Property
</Text>
<Text fontSize={"lg"} mt={4}>
    The content on GorillaFunder is protected in various ways. You may use it for certain personal non-commercial purposes only. You must ask us for permission before using it for any commercial purpose or advantage.
</Text>
<Text fontSize={"lg"} mt={4}>
GorillaFunder’s Services are legally protected in various ways, including copyrights, trademarks, service marks, patents, trade secrets, and other rights and laws. You agree to respect all copyright and other legal notices, information, and restrictions contained in any content accessed through the Site. You also agree not to change, translate, or otherwise create derivative works of the Service.
</Text>
<Text fontSize={"lg"} mt={4}>
    GorillaFunder grants you a non-exclusive, worldwide, non-sublicensable, non-transferable, revocable license to reproduce content from the Services for personal non-commercial use only. This license covers both GorillaFunder’s own protected content and user-generated content on the Site. If you want to do anything else with content on our Site, such as use it for commercial advantage or for a commercial purpose, you need prior written permission from GorillaFunder or the relevant copyright holder. A “commercial purpose” means any use, sale, license, rent, or other exploitive or commercial use.
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Section 11: How We Deal with Copyright Issues
</Text>
<Text fontSize={"lg"} mt={4}>
    We comply with the Digital Millennium Copyright Act. To learn more about how we deal with claims of copyright infringement, read our Copyright Policy.
</Text>
<Text fontSize={"lg"} mt={4}>
    GorillaFunder has adopted a general policy regarding copyright in accordance with the Digital Millennium Copyright Act of 1998 (the “DMCA”). GorillaFunder complies with the DMCA, and we respond to notices of alleged infringement if they comply with the law and the requirements set forth in our Copyright Policy. We reserve the right to delete or disable content alleged to be infringing, and to terminate accounts for repeat infringers. (We do this when appropriate and at our sole discretion.)
</Text>
<Text fontSize={"lg"} mt={4}>
    If you’d like to submit a claim of copyright infringement, please visit our Copyright Policy where you can find contact information for our designated agent and additional information about how to submit a notice or counter-notice.
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Section 12: Deleting Your Account
</Text>
<Text fontSize={"lg"} mt={4}>
    You can delete your account at any time, but doing so may not automatically remove all content.
</Text>
<Text fontSize={"lg"} mt={4}>
    You can delete your account at any time through your account settings. As permitted by law, we may retain certain information such as information necessary for our legitimate business purposes. These Terms remain in effect as to your prior and any ongoing use of our Site and Services by you, including our rights regarding any Content you’ve already submitted to the Site. For instance, if you’ve launched a project, deleting your account will not automatically remove the project from the Site. Similarly, if you successfully ran a campaign that funded on our Site, deleting your account will not delete, remove or hide your project because, among other things, of the agreement you have with backers. You can contact us at GorillaFunder Support for additional information or if you believe special circumstances warrant removal of your project page.
</Text>
<Text fontSize={"lg"} mt={4}>
    For more information about account deletions and what it involves, visit our Privacy Policy.
</Text>







<Box>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
  Section 13: Our Rights
</Text>
<Text fontSize={"lg"} mt={4}>
  To operate, we need to be able to maintain control over what happens on our Site. We reserve the right to make decisions and to take any action we believe necessary or appropriate to protect the health and integrity of our system. We don’t exercise these powers lightly, and we only use them when necessary.
</Text>
<Text fontSize={"lg"} mt={4}>
  To protect the health and integrity of our system and to help ensure that creators and backers enjoy a safe and secure environment, GorillaFunder reserves these rights:
</Text>
<Text fontSize={"lg"} mt={4}>
  We can make changes to the GorillaFunder Site and Services without notice or liability at any time.
</Text>
<Text fontSize={"lg"} mt={4}>
  We have the right to decide who’s eligible to use GorillaFunder. We may in limited circumstances impose restrictions or limitations on accounts. For particularly significant or repeated violations of our Terms or any other rules on the Site, like our Community Guidelines and rules for starting projects, we may cancel accounts or decline to offer our Services. (Especially if you’re abusing them.) We can change our eligibility criteria at any time. If our Services are prohibited by law where you live, then you may not use our Site or our Services, and our Site and Services are not available and may not be used by you.
</Text>
<Text fontSize={"lg"} mt={4}>
  We have the right to cancel any pledge to any project, at any time and for any reason.
</Text>
<Text fontSize={"lg"} mt={4}>
  We have the right to reject, cancel, interrupt, remove, or suspend any project, at any time and for any reason.
</Text>
<Text fontSize={"lg"} mt={4}>
  We have the right to cause funds to be withheld to comply with laws and regulations.
</Text>
<Text fontSize={"lg"} mt={4}>
  We have the right to force refunds of amounts collected by our payment processor prior to payout if we deem it necessary, appropriate or desirable in order to resolve any dispute that may arise between a creator and any third party related to the creator’s use of our Site or Services.
</Text>
<Text fontSize={"lg"} mt={4}>
  We have the right to verify your identity and investigate your account details at any time. You agree to cooperate promptly with our verification requests and investigations, including by engaging with us through any reasonable means (such as live video sessions) and providing us with additional information as we may request, for the legitimate purposes of protecting the integrity of our platform and preventing fraud or other wrongful conduct. If you refuse to cooperate, we may cancel your project and/or your account, suspend payout of campaign funding until we are satisfied or force refunds, or take any other action we deem appropriate in our sole discretion.
</Text>
<Text fontSize={"lg"} mt={4}>
  GorillaFunder is not liable for any damages as a result of any of these actions.
</Text>
<Text fontSize={"lg"} mt={4}>
  It is our general policy not to comment publicly on the underlying reasons or the details of any such action. However, if we take any action in reliance on these rights, we will let the account holder know the reasons for this action and whether, if at all, they can remedy any issues except if we’re prohibited from doing so by law or in the interest of safety. For account cancellations, we will attempt to let the account holder know the reasons for this action in advance unless we’re canceling for reasons related to illicit or inappropriate content, the safety of a good or service, counterfeiting, fraud, malware, spam, data breaches, other cybersecurity risks, or the suitability of a good or service.
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
  Section 14: Warranty Disclaimer
</Text>
<Text fontSize={"lg"} mt={4}>
  We work hard to provide you with great services, but we can’t guarantee everything will always work perfectly. This Site and the Services are presented and provided as-is, without warranties.
</Text>
<Text fontSize={"lg"} mt={4}>
  You use our Site and Services solely at your own risk. They are provided to you “as is” and “as available” and without warranty of any kind, express or implied.
</Text>
<Text fontSize={"lg"} mt={4}>
GorillaFunder SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE. NO ADVICE OR INFORMATION (ORAL OR WRITTEN) OBTAINED BY YOU FROM GorillaFunder SHALL CREATE ANY WARRANTY.
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
  Section 15: Indemnification
</Text>
<Text fontSize={"lg"} mt={4}>
  If you do something on GorillaFunder that winds up getting us involved in a dispute or sued, you have to help defend us.
</Text>
<Text fontSize={"lg"} mt={4}>
  If you do anything that results in our involvement in a dispute or threatened with legal action or sued, or break any of the promises you make in these Terms, you agree to defend, indemnify, and hold us harmless from all liabilities, claims, and expenses (including reasonable attorneys’ fees and other legal costs) that arise from or relate to your use or misuse of our Site or Services. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to this indemnification clause, in which case you agree that you’ll cooperate and help us in asserting any defenses.
</Text>
<Text fontSize={"lg"} mt={4}>
  As part of this, by accepting these Terms of Use, you consent to service of process for any action that could be brought against GorillaFunder, for any reason.
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
  Section 16: Limitation of Liability
</Text>
<Text fontSize={"lg"} mt={4}>
  If something bad happens as a result of your use of our Site or Services, we’re not liable (beyond a small amount).
</Text>
<Text fontSize={"lg"} mt={4}>
  To the fullest extent permitted by law, in no event will GorillaFunder, its directors, employees, partners, suppliers, or content providers be liable for any indirect, incidental, punitive, consequential, special, or exemplary damages of any kind, including but not limited to damages (i) resulting from your access to, use of, or inability to access or use the Site or Services; (ii) for any lost profits, data loss, or cost of procurement or substitute goods or services; or (iii) for any conduct of content of any third party on the Site. In no event shall GorillaFunder’s liability for direct damages be in excess of (in the aggregate) one hundred U.S. dollars ($100.00).
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
  Section 17: Dispute Resolution and Governing Law
</Text>
<Text fontSize={"lg"} mt={4}>
  We’re located in New York, and any disputes with us have to be handled in New York under New York State law.
</Text>
<Text fontSize={"lg"} mt={4}>
  We at GorillaFunder encourage you to contact us if you’re having an issue before resorting to the courts. Our GorillaFunder support team is on hand and ready to answer your questions. You can visit our Help Center to find articles with information that may resolve your issue. If you still have questions, you can click “Contact us” in the Help Center so that one of our support team agents can personally reply to you and attempt to resolve your issue. These resources are easily accessible and free.
</Text>
<Text fontSize={"lg"} mt={4}>
  If you’re a creator based in the European Union and, after contacting us through those channels, you still don’t feel as if your issue has been resolved, we may agree with you to engage in mediation to resolve any complaints in good faith. You may address your complaint with one of these two mediation organizations:
</Text>
<Text fontSize={"lg"} mt={4}>
  The Centre for Effective Dispute Resolution (CEDR)
</Text>
<Text fontSize={"lg"} mt={4}>
  IPOS Mediation
</Text>
<Text fontSize={"lg"} mt={4}>
  In the unfortunate situation where legal action does arise, these Terms (and all other rules, policies, or guidelines incorporated by reference) will be governed by and construed in accordance with the laws of the State of New York and the United States, without giving effect to any principles of conflicts of law, and without application of the Uniform Computer Information Transaction Act or the United Nations Convention of Controls for International Sale of Goods. You agree that GorillaFunder and its Services are deemed a passive website that does not give rise to jurisdiction over GorillaFunder or its parents, subsidiaries, affiliates, assigns, employees, agents, directors, officers, or shareholders, either specific or general, in any jurisdiction other than the State of New York. You agree that if you wish to file, commence, or prosecute any claim, action, lawsuit or proceeding of any kind, at law or in equity, arising out of or relating to these Terms or your use or non-use of GorillaFunder, you will do so only in a state or federal court located in New York County in the State of New York, and will not file, commence, or prosecute any such claim, action, lawsuit or proceeding outside of that forum. You agree that any claims, actions, lawsuits, or proceedings shall be brought individually and not consolidated with any other claims, actions, lawsuits, or proceedings. You and GorillaFunder agree that any dispute, claim, or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation, or validity thereof, will be settled by binding arbitration administered by the American Arbitration Association (AAA) in accordance with its Consumer Arbitration Rules, and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. The arbitrator shall be selected by the parties from the AAA panel of consumer arbitrators.
</Text>
<Text fontSize={"lg"} mt={4}>
  This arbitration provision shall survive termination of these Terms.
</Text>


<Box>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
  Section 18: The Rest
</Text>
<Text fontSize={"lg"} mt={4}>
  These are our official terms and our rules for how things work. You can verify any confusing or conflicting information you see elsewhere by referencing what we’ve explained on this page.
</Text>
<Text fontSize={"lg"} mt={4}>
  These Terms and the other material referenced in them are the entire agreement between you and GorillaFunder with respect to the Site and Services. They supersede all other communications and proposals (whether oral, written, or electronic) between you and GorillaFunder with respect to the Site and Services and govern our future relationship. If any provision of these Terms is found to be invalid under the law, that provision will be limited or eliminated to the minimum extent necessary so that the Terms otherwise will remain in full force and effect and enforceable. The failure of either you or GorillaFunder to exercise any right provided for in these Terms in any way won’t be deemed a waiver of any other rights.
</Text>
<Text fontSize={"lg"} mt={4}>
  These Terms are personal to you. You can’t assign them, transfer them, or sublicense them unless you get GorillaFunder’s prior written consent. GorillaFunder has the right to assign, transfer, or delegate any of its rights and obligations under these Terms without your consent. GorillaFunder will provide you notice via email, written notice, or by conspicuously posting the notice on our Site.
</Text>

</Box>
</Box>





{/* <UnorderedList typeof='' mt={"20px"}>
            
            <ListItem mt={4}><b>Don’t try to interfere with the proper workings of the Services.</b></ListItem>
          
          </UnorderedList> */}

          </Box>
        </Container>
      </VStack>
      <Footer />
    </>
  );
};

export default TermsOfUse;
