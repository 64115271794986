import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Image } from "@chakra-ui/react";

function SimpleSlider() {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    pauseOnHover: false,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    // cssEase: "linear",
  };

  return (
    <Box className="slider-container" overflow={"hidden"}>
      <Slider ref={sliderRef} {...settings}>
        <Box h={"95vh"} position={"relative"}>
          <Image
            w={"100%"}
            h={"100%"}
            objectFit={"cover"}
            src="/images/movies/1-min.png"
            alt="Slide 1"
          />
         
        </Box>
        <Box h={"95vh"} position={"relative"}>
          <Image
            w={"100%"}
            h={"100%"}
            objectFit={"cover"}
            src="/images/movies/LOWER-FEES-2-min.png"
            alt="Slide 2"
          />
          
        </Box>
        <Box h={"95vh"} position={"relative"}>
          <Image
            w={"100%"}
            h={"100%"}
            objectFit={"cover"}
            src="/images/movies/LOWER-FEES-1-min.png"
            alt="Slide 3"
          />
          
        </Box>
      
      </Slider>
    </Box>
  );
}

export default SimpleSlider;
